import { Dropdown, MenuProps, message } from 'antd'
import classnames from 'classnames'
import React, { FC, SyntheticEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../button/Button'

import { SvgIcon } from '../icon'
import Typography from '../typography/Typography'
import './DoubleDotsMenu.less'

const { Text } = Typography
export type MenuItemsType = {
  label: string
  link?: string
  onClick?: (id: string) => void
  downloadLink?: string
  disabled?: boolean
}[]
type Props = {
  color?: 'gray' | 'black'
  menuItems: Partial<MenuItemsType>
  disabled?: boolean
  entityId?: Maybe<string>
}
const DoubleDotsMenu: FC<Props> = ({ entityId, menuItems, color = 'black', disabled = false }) => {
  const items: MenuProps['items'] = menuItems?.map((it, index) => {
    if (it?.downloadLink) {
      return ({
        key: index,
        label: (
          <a href={it?.downloadLink} download={'uploaded_document'} target={'_blank'}>
            <Text>{it?.label}</Text>
          </a>
        ),
        disabled: it?.disabled ? it?.disabled : false
      })
    }
    if (it?.label && !it?.onClick && it?.link) {
      return ({
        key: index,
        label: <Link to={it?.link} target={'_blank'}>
          <Text>{it?.label}</Text>
        </Link>,
        disabled: it?.disabled ? it?.disabled : false
      })
    }
    return ({
      key: index,
      label: (
        <Button
          btnType={'text'}
          size={'small'}
          style={{ height: 20, justifyContent: 'start', padding: 0, width: '100%' }}
          onClick={() => {
            return it?.onClick && it?.onClick(entityId as string)
          }}
        >
          <Text>
            {it?.label}
          </Text>
        </Button>

      ),
      disabled: it?.disabled ? it?.disabled : false
    })
  })


  const dotsClasses = classnames({
    'dots-menu-button': true,
    'gray': color === 'gray',
    'black': color === 'black',
  })
  const onItemClick = (event: SyntheticEvent) => {
    event.stopPropagation()
  }
  const onClick: MenuProps['onClick'] = ({ key, domEvent }) => {
    domEvent.stopPropagation()
  }
  return disabled ? (
    <Button className={dotsClasses} btnType={'icon'} icon={<SvgIcon type={'double-gray-dots'} />} disabled={true} />
  ) : (
    <Dropdown
      menu={{ items, onClick }}
      overlayClassName={'notification-dropdown'}
      placement={'bottomRight'}
      trigger={['click']}
    >
      <Button className={dotsClasses} btnType={'icon'} icon={<SvgIcon type={'double-gray-dots'} />} onClick={onItemClick} />
    </Dropdown>
  )
}

export default DoubleDotsMenu
