import { bbox } from '@turf/turf'
import { MapRef } from 'react-map-gl'
import React from 'react'
import { FeatureCollection, Geometry, GeometryCollection } from 'geojson'

export const mapFitBounds = (mapRef: React.MutableRefObject<MapRef | undefined>, geoJson: FeatureCollection<Geometry | GeometryCollection> | null): void => {

  if (!mapRef?.current) {
    console.error('mapRef is not properly defined');
    return;
  }

  if (!geoJson || !geoJson.features || geoJson.features.length === 0) {
    console.error('geoJson is not properly defined');
    return;
  }

  try {
    const boundingBox = bbox(geoJson);

    const [minLng, minLat, maxLng, maxLat] = boundingBox;
    mapRef.current.fitBounds(
      [[minLng, minLat], [maxLng, maxLat]],
      { padding: 100, duration: 1000 }
    );
    return
  } catch (error) {
    console.error('Error computing bounding box or fitting bounds:', error);
  }
};
