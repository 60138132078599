import { message } from 'antd'
import axios, { AxiosRequestConfig } from 'axios'
import { FeatureCollection } from '@turf/turf'

export const getGeoJsonFromUrl = async (url: string | undefined) => {
  if (url) {
    try {
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      }
      const geoJsonResponseContent = (await axios.get(url, config)).data
      return geoJsonResponseContent as FeatureCollection

    } catch (error: ErrorEvent | any) {
      message.error(
        error?.message
          ? error?.message + 'Error converting to geoJson'
          : 'Error converting to geoJson',
      )
    }
  }
  return null
}