import { useAuth } from '@/components/context/AuthProvider'
import { useGeneralContext } from '@/components/context/GeneralContext'
import DoubleDotsMenu from '@/components/double-dots-menu/DoubleDotsMenu'
import Status from '@/components/status/Status'
import Typography from '@/components/typography/Typography'
import UserAvatar from '@/components/user-avatar/UserAvatar'
import application from '@/pages/application/Application'
import { Col, Row } from 'antd'
import classnames from 'classnames'
import { BaseSyntheticEvent, FC, SyntheticEvent, useEffect, useState } from 'react'
import './AgentCard.less'


const { Text } = Typography
type Props = {
  userData: UsersPermissionsUserEntity | undefined
  isSelected?: boolean
  onCardClick?: (id: string) => void
  isSelectable?: boolean
  onDelete?: (id: string) => void
  infoItem?: ComponentDataAgentInfo
}
const AgentCard: FC<Props> = ({infoItem, onDelete, userData, isSelected, onCardClick, isSelectable = false }) => {
  const {user} = useAuth()
  const [isCardOwner, setIsCardOwner] = useState(false)
  const {setIsModalOpen, setModalType, setCurrentId} = useGeneralContext()
  const userFullName = userData?.attributes?.userProfile?.data?.attributes?.fullName || ''
  const avatarImageUrl = userData?.attributes?.userProfile?.data?.attributes?.avatar?.data?.attributes?.url || ''

  useEffect(() => {
    if (user?.id === userData?.id) {
      setIsCardOwner(true)
    } else {
      setIsCardOwner(false)
    }
  }, [user?.id, userData?.id])

  const cardClasses = classnames({
    'agent-card': true,
    'active': isSelected,
    'isSelectable': isSelectable,
  })
  const radioClasses = classnames({
    'agent-card-radio': true,
    'active': isSelected,
  })
  
  const handleCardClick = (e: BaseSyntheticEvent) => {
    (isSelectable && onCardClick) ? onCardClick(userData?.id || '') : ''
  }

  const baseMenuItems = [
    {
      label: 'Remove agent',
      link: '#',
      onClick: () => {
        onDelete && onDelete(userData?.id as string)
      },
      disabled: true
    },
    {
      label: 'Send notification',
      link: '#',
      onClick: () => {
        setIsModalOpen(true)
        setModalType('agent-assign-notification')
        setCurrentId(userData?.id || '')
      }
    },
  ]
  const menuItems = isCardOwner ? [
    ...baseMenuItems, {
    label: 'Change status',
    link: '#',
    onClick: () => {
      setIsModalOpen(true)
      setModalType('change-agent-status')
      setCurrentId(infoItem?.id || '')
    }
  },
  ] : [...baseMenuItems]

  return (
    <div
      // btnType={'ghost'}
      className={cardClasses}
      onClick={(e) => handleCardClick(e)}
    >
      <UserAvatar avatarImageUrl={avatarImageUrl} userFullName={userFullName} />
      <div className={'agent-selected-info'}>
        <Row style={{ width: '100%' }}>
          <Col xs={12}>
            <div className={'info-item'}>
              <Text weight={'w600'}>{userData?.attributes?.userProfile?.data?.attributes?.fullName}</Text>
              <Text>{userData?.attributes?.email}</Text>
            </div>
          </Col>
          <Col xs={12}>
            <div className={'info-item'}>
              <Text weight={'w600'}>{userData?.attributes?.organization?.data?.attributes?.name}</Text>
            </div>
          </Col>
        </Row>
      </div>
      <div className={'agent-menu'}>
        {isSelectable ? <div className={radioClasses} /> : (
          <>
            <Status status={infoItem?.assignStatus as EnumComponentdataagentinfoAssignstatus} />
            <div style={{ paddingLeft: 12 }}>
              <DoubleDotsMenu menuItems={menuItems} />
              {/*<Button  btnType={'icon'} icon={<SvgIcon type={'double-gray-dots'} onClick={onCardMenuClick} />} />*/}
            </div>

          </>
        )
        }
      </div>
    </div>
  )
}

export default AgentCard
