import React, { FC } from 'react'
import { Layer, Source } from 'react-map-gl'


type Props = {
  geometry: AreaEntity[] | ProvinceEntity[]
  stroke?: string
  fill?: string
}
const MapLayersRenderer: FC<Props> = ({ geometry, stroke, fill }) => {
  return (
    <>
      {geometry?.map(it => {
        if (!it?.attributes?.geoJson?.data) return null
        return (
          <React.Fragment key={it?.id}>
            <Source
              id={it?.id as string}
              type='geojson'
              data={it?.attributes?.geoJson?.data?.attributes?.url}
            >
              <Layer
                id={it?.id as string}
                type='line'
                paint={{
                  'line-width': 2,
                  'line-color': stroke ? stroke : '#005296',
                }}
              />
            </Source>
            {fill && (
              <Source
                id={it?.id + 'fill' as string}
                type='geojson'
                data={it?.attributes?.geoJson?.data?.attributes?.url}
              >
                <Layer
                  id={it?.id + 'fill' as string}
                  type='fill'
                  paint={{
                    'fill-opacity': 0.1,
                    'fill-color': fill,
                  }}
                />
              </Source>
            )}
          </React.Fragment>

        )
      })}
    </>
  )
}

export default MapLayersRenderer
