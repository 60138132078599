import FormItem from '@/components/form-item/FormItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { Loader } from '@/components/layout/Loader'
import application from '@/pages/application/Application'
import { Form, Row, message } from 'antd'
import { useState } from 'react'
import {
  useApplicationQuery,
  useApplicationsQuery,
  useDeleteApplicationMutation,
  useDeleteProjectMutation,
  useSendAgentAssignNotificationMutation,
  useUserQuery,
} from '@/graphql'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const AgentAssignNotification = () => {
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id') as string
  const { data, loading: applicationLoading } = useApplicationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { uuid: { eq: uuid } } },
  })
  const { setIsModalOpen, currentId, setTriggerRefetch } = useGeneralContext()

  const {data: userData, loading: userDataLoading} = useUserQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const currentAgent = userData?.usersPermissionsUser?.data as UsersPermissionsUserEntity
  const fullName = currentAgent?.attributes?.userProfile?.data?.attributes?.fullName
  const currentApplication = data?.applications?.data?.[0]
  const [sendNotification, { loading }] = useSendAgentAssignNotificationMutation()
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onSend = async (values: {message: string}) => {
    console.log(values)

    currentApplication?.id && currentAgent?.id && await sendNotification({
      variables: {
        input: {
          applicationId: currentApplication?.id,

          agentId: currentAgent?.id,
          message: values.message
        }
      },
      fetchPolicy: globalFetchPolicy,
      refetchQueries: 'active',
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        BlackNotification('Email notification sent successfully')
        setIsModalOpen(false)
        setTriggerRefetch(true)
      },
    })
  }
  const initialValues = {
    to: currentAgent?.attributes?.email,
    default_message: `Hello ${fullName}! You have been assigned to the application No ${currentApplication?.attributes?.title}. Please, confirm by changing the application status.`
  }
  if (userDataLoading || applicationLoading) return <Loader />
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Agent Email Notification'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
        {`You are going to send email notification to selected agent `}
        <Text size={'md'} weight={'w600'}>{fullName}</Text>
      </Paragraph>
      <Form layout={'vertical'} initialValues={initialValues} onFinish={onSend}>
        <FormItem name={'to'} type={'input'} label={'Recipient email'} placeholder={'Email'} isDisabled={true}/>
        <FormItem name={'default_message'} type={'textarea'} label={'Default message'} placeholder={'Message'} isDisabled={true}/>
        <FormItem name={'message'} type={'textarea'} label={'Message (optional)'} placeholder={'Message'} required={false}/>
        <Row justify={'end'}>
          <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
          <Button htmlType={'submit'} text={'Send notification'} btnType={'primary'} loading={loading} />
        </Row>
      </Form>
    </>
  )
}
export default AgentAssignNotification
