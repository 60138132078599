import { Feature, Geometry, lineChunk, LineString, Point, point, Position } from '@turf/turf'

export const lineChunkPointsGenerator = (feature: Feature<Geometry>, chunkLength: number): Feature<Point>[] => {

  if (feature?.geometry?.type === 'LineString') {
    const currentFeature = feature as unknown as Feature<LineString>
    const chunkedLine = lineChunk(currentFeature, chunkLength, { units: 'meters' })

    return chunkedLine?.features?.reduce((accum, it, index) => {
      if (index === 0) {
        accum.push(point(it?.geometry?.coordinates?.[0] as Position) as never)
      }
      accum.push(point(it?.geometry?.coordinates?.[1] as Position) as never)
      return accum
    }, [])

  } else {
    throw new Error('Incorrect feature type passed inside LineChunkGenerator')
  }
}