import { GeometryCollection } from '@turf/helpers/dist/js/lib/geojson'
import React, { FC } from 'react'
import { Layer, Marker, Source } from 'react-map-gl'


type Props = {
  geometryCollection: GeometryCollection
}
const GeometryCollectionRender: FC<Props> = ({ geometryCollection }) => {
  return (
    <>
      {geometryCollection?.geometries?.map((it, index) => {

        if (it?.type === 'Point') {
          return (
            <Marker key={index} longitude={it?.coordinates?.[0]} latitude={it?.coordinates?.[1]} />
          )
        }
        if (it?.type === 'LineString') {
          return (
            <Source
              key={'project' + index}
              id={'project' + index}
              type='geojson'
              data={it}
            >
              <Layer
                id={'project' + index}
                type='line'
                paint={{
                  'line-width': 2,
                  'line-color': `red`,
                }}
              />
            </Source>
          )
        }
        if (it?.type === 'Polygon') {
          return (
            <>
              <Source
                key={'border' + index}
                id={'project' + index}
                type='geojson'
                data={it}
              >
                <Layer
                  id={'project' + index}
                  type='line'
                  paint={{
                    'line-color': 'red',
                  }}
                />
              </Source>
              <Source
                key={'fill' + index}
                id={'fill' + index}
                type='geojson'
                data={it}
              >
                <Layer
                  id={'fill' + index}
                  type='fill'
                  paint={{
                    'fill-color': '#bd6868',
                    'fill-opacity': 0.1,
                  }}
                />
              </Source>
            </>
          )
        }
        return
      })
      }
    </>
  )
}

export default GeometryCollectionRender
