import { createContext, Dispatch, SetStateAction, useContext } from 'react'

type ModalTypes =
  | 'simple'
  | 'register-success'
  | 'providers-attach'
  | 'create-application-info'
  | 'create-application-success'
  | 'create-application'
  | 'create-application-organization'
  | 'create-payment'
  | 'application-report'
  | 'application-assign-confirmation'
  | 'map-saving-info'
  | 'application-assign-step-01'
  | 'application-assign-step-02'
  | 'application-info'
  | 'selected-project-info'
  | 'project-created-success'
  | 'request-info'
  | 'approve-application'
  | 'payment-options'
  | 'payment-success'
  | 'payment-approve'
  | 'invoice-approve'
  | 'upload-receipt'
  | 'assign-project'
  | 'generated-applications'
  | 'assign-user'
  | 'create-company'
  | 'send-quotation'
  | 'confirm-quotation'
  | 'create-quotation-item'
  | 'user-profile-review'
  | 'delete-project-confirmation'
  | 'delete-application-confirmation'
  | 'create-kickoff-invite'
  | 'create-kickoff-record'
  | 'delete-kickoff-record'
  | 'delete-kickoff-invite'
  | 'agent-assign'
  | 'agent-assign-notification'
  | 'change-agent-status'
  | 'change-geometry-category'
  | ''
type SimpleModalSettings = {
  buttonLink?: string
  buttonText?: string
  content: string
  title: string
  onClick?: () => void
}
export type UserLevelOfApproval = {
  userId: string
  level: string
}[]
type SidebarMode = 'default' | 'user-info' | 'app-info' | 'project-info' | 'project-overview'
export type SignUpMode = 'register' | 'create-agent' | 'create-customer'
export type PageViewMode = 'grid' | 'table'
type ContextProps = {
  createAppCurrentStep: number
  currentApplicationId: string
  currentId: string
  documentInfo: Record<string, string>
  isModalOpen: boolean
  isPaymentCreated: boolean
  isReadyForReview: boolean
  locationConfirmationFormStep: string
  modalSettings: SimpleModalSettings
  modalType: ModalTypes
  modalWidth: null | number
  setModalWidth: Dispatch<SetStateAction<number | null>>
  modalTopOffset: null | number
  setModalTopOffset: Dispatch<SetStateAction<number | null>>
  setIsPaymentCreated: Dispatch<SetStateAction<boolean>>
  setIsReadyForReview: Dispatch<SetStateAction<boolean>>
  setCreateAppCurrentStep: Dispatch<SetStateAction<number>>
  setCurrentApplicationId: Dispatch<SetStateAction<string>>
  setCurrentId: Dispatch<SetStateAction<string>>
  setDocumentInfo: Dispatch<SetStateAction<Record<string, string>>>
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  setLocationConfirmationFormStep: Dispatch<SetStateAction<string>>
  setModalSettings: Dispatch<SetStateAction<SimpleModalSettings>>
  setModalType: Dispatch<SetStateAction<ModalTypes>>
  setSidebarMode: Dispatch<SetStateAction<SidebarMode>>
  setSignUpMode: Dispatch<SetStateAction<SignUpMode>>
  setTriggerRefetch: Dispatch<SetStateAction<boolean | null>>
  sidebarMode: SidebarMode
  signUpMode: SignUpMode
  triggerRefetch: boolean | null
  userLevelOfApproval: UserLevelOfApproval
  setUserLevelOfApproval: Dispatch<SetStateAction<UserLevelOfApproval>>
  mode: string
  setMode: Dispatch<SetStateAction<string>>
  pageViewMode: PageViewMode
  setPageViewMode: Dispatch<SetStateAction<PageViewMode>>
  tabActiveKey: string
  setTabActiveKey: Dispatch<SetStateAction<string>>
}
export const defaultValues: ContextProps = {
  sidebarMode: 'default',
  setSidebarMode: () => null,
  modalType: '',
  modalWidth: null,
  setModalWidth: () => null,
  modalTopOffset: null,
  setModalTopOffset: () => null,
  setModalType: () => null,
  isModalOpen: false,
  isReadyForReview: false,
  isPaymentCreated: false,
  setIsPaymentCreated: () => null,
  setIsReadyForReview: () => null,
  setIsModalOpen: () => null,
  locationConfirmationFormStep: '1',
  setLocationConfirmationFormStep: () => null,
  createAppCurrentStep: 1,
  setCreateAppCurrentStep: () => null,
  triggerRefetch: false,
  setTriggerRefetch: () => null,
  modalSettings: { title: '', content: '' },
  setModalSettings: () => null,
  currentApplicationId: '',
  setCurrentApplicationId: () => null,
  currentId: '',
  setCurrentId: () => null,
  documentInfo: {},
  setDocumentInfo: () => null,
  signUpMode: 'register',
  setSignUpMode: () => null,
  userLevelOfApproval: [],
  setUserLevelOfApproval: () => null,
  mode: '',
  setMode: () => null,
  pageViewMode: 'table',
  setPageViewMode: () => null,
  tabActiveKey: '1',
  setTabActiveKey: () => null,
}
const GeneralContext = createContext(defaultValues)
const useGeneralContext = () => useContext(GeneralContext)

export { GeneralContext, useGeneralContext }
