import { useAuth } from '@/components/context/AuthProvider'
import { Loader } from '@/components/layout/Loader'
import SidebarNew from '@/components/sidebar-new/SidebarNew'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { Layout } from 'antd'
import { FC, Suspense, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'
import './index.less'


export const globalFetchPolicy = 'no-cache'
const DashboardLayout: FC = () => {
  const {user, getUser} = useAuth()
  const params = useParams()
  const currentOrgSlug = params.org || EASETECH_COMPANY
  const navigate = useNavigate()

  useEffect(() => {
    const checkUser = async () => {
      const checkedUser = await getUser()

      if(!checkedUser) {
        localStorage.removeItem('userToken')
        navigate(`/${currentOrgSlug}/home`)
      }
    }
    if(!user) {
      checkUser().then()
    }
  }, [currentOrgSlug, navigate, user])
  
  

  return (
    <Layout style={{ minHeight: '100vh' }} className={'platform-layout'}>
      <SidebarNew />
      <Layout className={'dashboard-layout'}>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Layout>
    </Layout>
  )
}
export { DashboardLayout }
