import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useApplicationsQuery } from '@/graphql'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'

const useApplicationByUuid = (): {data: ApplicationsQuery | undefined, loading: boolean} => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('id') as string;

  const { data, loading } = useApplicationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { uuid: { eq: uuid } } },
  });

  return useMemo(() => ({
    data,
    loading,
  }), [data, loading]);
};

export default useApplicationByUuid;
