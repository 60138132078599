import Button from '@/components/button/Button'
import Typography from '@/components/typography/Typography'
import SliderInput from '@/pages/application/content/approval-details/slider-input/SliderInput'
import { Radio, Switch, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'

import { FC } from 'react'

const { Text } = Typography
export type GeometryDataItem = {
  id: string
  title: string
  isDisabled: boolean
  isCheckDisabled: boolean
  onShowChange: (value: boolean) => void
  onRunCheck: ()=>void
  onRangeChange: (value: number) => void
  isLoading: boolean
  isAffected: boolean
  initialBuffer: number
}
type Props = {
  dataSource: GeometryDataItem[]
  loading: boolean
}
const GeometryTable: FC<Props> = ({loading, dataSource}) => {

  const geometryColumns: ColumnsType<GeometryDataItem> = [
    {
      title: 'type',
      key: 'title',
      render: (item: GeometryDataItem) => {
        const tooltipContent = item?.id === 'general' ? 'The general category of geometry is used as the default company geometry and serves to determine whether the project is impacted by the geometry during the initial step of application creation' : "The utility category of geometry is used as a more detailed geometry and is employed to assess, with greater precision, whether the project is affected by the geometry"
        return (
          <Tooltip title={tooltipContent} placement={'topLeft'}>
            <Text size={'md'}>{item?.title}</Text>
          </Tooltip>
        )
      },
    },
    {
      title: 'show',
      key: 'show',
      render: (item: GeometryDataItem) => (
        <Switch onChange={item?.onShowChange} disabled={item?.isDisabled}/>
      ),
    },
    {
      title: 'actions',
      key: 'actions',
      render: (item: GeometryDataItem) => {
        return item?.id === 'general' ? '—' : (
          <Button disabled={item?.isCheckDisabled} onClick={item?.onRunCheck} text={'Run check'} btnType={'table-action'} loading={item?.isLoading} />
        )
      },
    },
    {
      title: 'buffer',
      key: 'buffer',
      render: (item) => {
        return item?.id === 'general' ? (<Text>{item?.initialBuffer}</Text>) : (
          <SliderInput onRangeChange={item?.onRangeChange} initialValue={item?.initialBuffer} />
        )
      },
    },
    {
      title: 'result',
      key: 'result',
      render: (item: GeometryDataItem) => (
        <Radio disabled checked={item?.isAffected} >{item?.isAffected ? 'Affected' : 'Not affected'}</Radio>
      ),
    },
  ]

  return (
    <Table
      className={'applications-table'}
      columns={geometryColumns}
      dataSource={dataSource}
      pagination={false}
      rowClassName={'table-row'}
      rowKey={'id'}
      loading={loading}
    />
  )
}

export default GeometryTable
