import { MapGeometry } from '@/components/google-map/types'
import { Feature, LineString, Point, pointToLineDistance, Properties } from '@turf/turf'
import { LineGeometryAffectedCheckResult } from '@/types'
import { GeometryCollection } from '@turf/helpers/dist/js/lib/geojson'

export const lineGeometryAffectedCheck = (lineAreas: MapGeometry[], project: Feature<Point>[] | null, precision: number): LineGeometryAffectedCheckResult => {
  const affectedIds: string[] = []
  const affectedPropsArray: {id: string, props: Properties}[] = []

  if (!project) throw new Error('No Project geometry found')

  for (const geometry of lineAreas) {
    const featuresArray = geometry?.featureCollection?.features as Feature<LineString | GeometryCollection>[]
    const lineStringFeatures = featuresArray?.filter(it => it?.geometry?.type === 'LineString')
    const geometryCollectionFeatures = featuresArray?.filter(it => it?.geometry?.type === 'GeometryCollection')


    for (const lineFeature of lineStringFeatures) {
      if (lineFeature?.geometry?.type !== 'LineString') {
        continue
      }
      let currentProps = lineFeature?.properties
      if (!currentProps) currentProps = { name: '' }

      if (lineFeature?.geometry?.type === 'LineString') {
        const currentLineItem = lineFeature as Feature<LineString>
        for (const point of project) {
          const distance = pointToLineDistance(
            point,
            currentLineItem,
            { units: 'meters' },
          )
          if (distance < precision) {
            affectedIds.push(geometry?.id)
            currentProps.geometryId = geometry?.id
            affectedPropsArray.push({id: geometry?.id, props: currentProps})
            break
          }
        }
      }
    }

    for (const collectionFeature of geometryCollectionFeatures) {
      if (collectionFeature?.geometry?.type !== 'GeometryCollection') {
        continue
      }
      let currentProps = collectionFeature?.properties
      if (!currentProps) currentProps = { name: '' }

      if (collectionFeature?.geometry?.type === 'GeometryCollection') {

        const currentGeometryCollection = collectionFeature?.geometry as GeometryCollection
        const featuresFromCurrentCollection = currentGeometryCollection?.geometries as unknown as LineString[]

        for (const lineFeature of featuresFromCurrentCollection) {
          if (lineFeature?.type !== 'LineString') continue

          if (lineFeature?.type === 'LineString') {
            const currentLineItem = lineFeature as LineString
            for (const point of project) {
              const distance = pointToLineDistance(
                point,
                currentLineItem,
                { units: 'meters' },
              )
              if (distance < precision) {
                affectedIds.push(geometry?.id)
                currentProps.geometryId = geometry?.id
                affectedPropsArray.push(currentProps)
                break
              }
            }
          }
        }
      }
    }
  }
  return {
    affectedIds: [...new Set(affectedIds)],
    affectedProps: affectedPropsArray,
  }
}