import { Feature, Polygon } from '@turf/turf'
import React, { FC } from 'react'
import { Layer, Source } from 'react-map-gl'


type Props = {
  feature: Feature<Polygon>
  index: number | string
}
const PolygonRender: FC<Props> = ({ feature, index }) => {
  return (
    <>
      <Source
        key={index}
        id={'project'}
        type='geojson'
        data={feature}
      >
        <Layer
          id={'project'}
          type='line'
          paint={{
            'line-width': 2,
            'line-color': `red`,
          }}
        />
      </Source>
      <Source
        key={'fill' + index}
        id={'fill'}
        type='geojson'
        data={feature}
      >
        <Layer
          id={'fill'}
          type='fill'
          paint={{
            'fill-color': '#e66a9b',
            'fill-opacity': 0.2,
          }}
        />
      </Source>
    </>
  )
}

export default PolygonRender
