import { websiteRoutes } from '@/router/routes'
import { Row } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph, Text } = Typography
const CreateApplicationOrganizationInfo: FC = () => {
  const navigate = useNavigate()

  const { dashboard } = websiteRoutes
  const { setIsModalOpen } = useGeneralContext()

  const onContinue = () => {
    setIsModalOpen(false)
    navigate(`${dashboard.createApplication}/application-details/1`)
  }

  return (
    <>
      <Title level={3}>{'Create application'}</Title>
      <div className={'text-block'} style={{ marginTop: 24 }}>
        <Text size={'sm'}>{'Here is a starting point of the Application creation flow'}</Text>
      </div>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {`You will now be directed to complete your application and enter the location details of your works. Our system will analyse this information and detect affected parties, infrastructure and services. `}
      </Paragraph>
      <Paragraph style={{ margin: '24px 0' }} size={'sm'}>
        {`A quote will then be processed for your review and approval – there will be zero obligation until you accept our quote.`}
      </Paragraph>
      <Row justify={'end'}>
        <Button text={'Continue'} btnType={'primary'} onClick={onContinue} />
      </Row>
    </>
  )
}
export default CreateApplicationOrganizationInfo
