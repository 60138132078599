import { Feature, FeatureCollection, Geometries, Geometry, LineString, Point, Polygon, polygonToLine } from '@turf/turf'
import { GeometryCollection } from '@turf/helpers/dist/js/lib/geojson'
import { lineChunkPointsGenerator } from '@/components/google-map/turf-functions/lineChunkPointsGenerator'

export const chunkProject = (featureCollection: FeatureCollection<Geometry | Geometries | GeometryCollection> | null, chunkSize: number) => {

  const singlePoints = featureCollection?.features?.filter((it) => {
    const currentFeature = it as unknown as Feature<Geometry>
    return currentFeature?.geometry?.type === 'Point'
  }) as unknown as Feature<Point>[]
  const singleLines = featureCollection?.features?.filter((it) => {
    const currentFeature = it as unknown as Feature<Geometry>
    return currentFeature?.geometry?.type === 'LineString'
  }) as unknown as Feature<LineString>[]
  const singlePolygons = featureCollection?.features?.filter((it) => {
    const currentFeature = it as unknown as Feature<Geometry>
    return currentFeature?.geometry?.type === 'Polygon'
  }) as unknown as Feature<Polygon>[]

  const geometryCollection = featureCollection?.features?.filter((it) => {
    const currentFeature = it as unknown as Feature<Geometry>
    return currentFeature?.geometry?.type === 'GeometryCollection'
  }) as Feature<GeometryCollection>[]

  let featuresArrayFromCollection = [] as Feature<Geometry>[]
  if (geometryCollection?.length) {

    const featuresFromCollection = geometryCollection?.[0]?.geometry?.geometries?.map(it => ({
      type: 'Feature',
      geometry: {
        type: it?.type,
        coordinates: it?.coordinates,
      },
    }) as Feature<Geometry>)

    featuresArrayFromCollection = [...featuresFromCollection]
  }

  const pointsItems = featuresArrayFromCollection?.filter(it => {
    return it?.geometry?.type === 'Point'
  })
  const lineItems = featuresArrayFromCollection?.filter(it => {
    return it?.geometry?.type === 'LineString'
  })
  const polygonItems = featuresArrayFromCollection?.filter(it => {
    return it?.geometry?.type === 'Polygon'
  })

  const totalPoints = [...singlePoints, ...pointsItems]
  const totalLines = [...singleLines, ...lineItems]
  const totalPolygons = [...singlePolygons, ...polygonItems]


  const linesFromPolygons = totalPolygons?.map(it => {
    const currentIt = it as unknown as Feature<Polygon>
    return polygonToLine(currentIt)
  }) as unknown as Feature<LineString>[]


  let chunkedLinesPolygons: Feature<Geometry>[] = []
  const linesArray = [...totalLines, ...linesFromPolygons]

  linesArray?.forEach(it => {
    const currentFeature = it as unknown as Feature<Geometry>
    const chunkedLine = lineChunkPointsGenerator(currentFeature, chunkSize) as unknown as Feature<Point>[]
    chunkedLinesPolygons = [...chunkedLinesPolygons, ...chunkedLine]
  })

  return [...totalPoints, ...chunkedLinesPolygons]
}