import { booleanPointInPolygon} from '@turf/turf'
import { MapGeometry } from '@/components/google-map/types'
import { PolygonGeometryAffectedCheckResult } from '@/types'
import { Feature, GeometryCollection, Polygon } from 'geojson'

export const polygonGeometryAffectedCheck = (
  polygonAreas: MapGeometry[],
  project: Feature[] | null): PolygonGeometryAffectedCheckResult => {

  if (!project) throw new Error('No project found')
  const affectedIds: string[] = []

  for (const area of polygonAreas) {
    for (const point of project) {
      for (const polygon of area?.featureCollection?.features) {
        if (polygon?.geometry?.type === 'Polygon') {
          const isInside = booleanPointInPolygon(point as Feature, polygon as unknown as Polygon)
          if (isInside) {
            affectedIds.push(area?.id)
            break
          }
        }
        if (polygon?.geometry?.type === 'GeometryCollection') {
          const geoCollection = polygon?.geometry as GeometryCollection
          for (const collectionItem of geoCollection?.geometries) {
            if (collectionItem?.type === 'Polygon') {
              const isInside = booleanPointInPolygon(point as Feature, collectionItem as unknown as Polygon)
              if (isInside) {
                affectedIds.push(area?.id)
                break
              }
            }
          }
        }
      }
    }
  }

  return {
    affectedIds,
  }
}