import BlackNotification from '@/components/blackNotification/BlackNotification'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useAreaQuery, useUpdateAreaMutation } from '@/graphql'
import { Form, Row, Spin } from 'antd'
import { FC, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const { Title, Paragraph } = Typography
const ChangeGeometryCategory: FC = () => {
  const { setIsModalOpen, currentId } = useGeneralContext()
  const [isDisabled, setIsDisabled] = useState(true)

  const [updateArea, { loading: areaUpdating }] = useUpdateAreaMutation()

  const { data, loading } = useAreaQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const currentArea = data?.area?.data as AreaEntity

  const selectOptions = [
    {
      label: 'General',
      value: 'general',
    },
    {
      label: 'Utility',
      value: 'utility',
    },
  ]

  const onFinish = async (value: { category: InputMaybe<EnumAreaCategory> }) => {
    console.log(value)
    await updateArea({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentId,
        data: {
          category: value?.category,
        },
      },
      onError: (error) => {
        console.log(error)
        BlackNotification('Error updating geometry category. Please try again.')
      },
      onCompleted: () => {
        BlackNotification('Geometry category updated successfully.')
      },
      refetchQueries: 'active',
    })
    setIsModalOpen(false)
  }

  const handleChange = () => {
    setIsDisabled(false)
  }
  const initialValues = {
    category: currentArea?.attributes?.category,
  }
  return (
    <>
      <Title level={3}>{'Change category'}</Title>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {'The Geometry category determines whether the geometry will be processed during the Create Application stage or be available for checking only at the Approval stage.'}
      </Paragraph>
      {loading ? (
        <Spin />
      ) : (
        <Form layout={'vertical'} className={'form-content'} initialValues={initialValues} onFinish={onFinish} >
          <FormItem
            notFoundText={'No empty projects...'}
            name={'category'}
            type={'select'}
            label={'Category'}
            placeholder={'Select the geometry category'}
            selectOptions={selectOptions}
            handleSelectChange={handleChange}
          />
          <Row justify={'end'}>
            <Button htmlType={'submit'} text={'Confirm selection'} btnType={'primary'} disabled={isDisabled} loading={areaUpdating} />
          </Row>
        </Form>
      )}
    </>
  )
}
export default ChangeGeometryCategory
