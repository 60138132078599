import { Col, InputNumber, Row, Slider } from 'antd';
import { FC, useEffect, useState } from 'react'

type Props = {
  onRangeChange: (value: number) => void
  initialValue: number
}
const SliderInput: FC<Props> = ({onRangeChange, initialValue = 5}) => {
  const [inputValue, setInputValue] = useState(initialValue);
  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])

  const onChange = (newValue: number | null) => {
    setInputValue(newValue || 1);
    onRangeChange(newValue || 1);
  };
  return (
    <Row style={{maxWidth: 140}}>
      <Col span={24}>
        <Slider
          min={1}
          max={20}
          onChange={onChange}
          value={typeof inputValue === 'number' ? inputValue : 0}
        />
      </Col>
      <Col span={24}>
        <InputNumber
          min={1}
          max={20}
          style={{ width: '100%',border: '1px solid #ccc' }}
          value={inputValue}
          onChange={onChange}
        />
      </Col>
    </Row>
  )
}

export default SliderInput
