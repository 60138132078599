import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import Status from '@/components/status/Status'
import { useUpdateApplicationMutation } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { Form, Row, Spin } from 'antd'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem, { SelectOptionsType } from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const { Title } = Typography
const ChangeAgentStatus: FC = () => {
  const [updateApplication, { loading: updatingApplication }] = useUpdateApplicationMutation()

  const { currentId, setIsModalOpen } = useGeneralContext()

  const { data, loading } = useApplicationByUuid()
  const currentApplication = data?.applications?.data?.[0] as ApplicationEntity
  console.log(currentApplication)
  const currentCard = currentApplication?.attributes?.agentsInfo?.find(it => it?.id === currentId) as ComponentDataAgentInfo
  console.log(currentCard)

  const statusSelectOptions = [
    {
      label: <Status status={'active'} />,
      value: 'active',
    },
    {
      label: <Status status={'pending'} />,
      value: 'pending',
    },
    {
      label: <Status status={'rejected'} />,
      value: 'rejected',
    },
    {
      label: <Status status={'stopped'} />,
      value: 'stopped',
    },
  ]

  const onFinish = async (value: { status: EnumComponentdataagentinfoAssignstatus }) => {
    console.log(value)
    const changedInfo = currentApplication?.attributes?.agentsInfo?.map(it => {
      if(it?.id === currentId) {
        return {
          agent: it?.agent?.data?.id,
          assignStatus: value?.status,
        }
      } else {
        return {
          agent: it?.agent?.data?.id,
          assignStatus: it?.assignStatus,
        }
      }

    }) || []

    const newAgentsInfo = [...changedInfo]
    currentApplication?.id && await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentApplication?.id,
        data: {
          agentsInfo: newAgentsInfo,
        },
      },
      onCompleted: () => {
        BlackNotification('Status updated successfully')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error changing status. ${error?.message || ''}`)
      },
      refetchQueries: 'active',
    })
  }
  const initialValues = {
    status: currentCard?.assignStatus || 'pending',
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <Title level={3}>{'Change agent status'}</Title>
      {loading ? (
        <Spin />
      ) : (
        <Form layout={'vertical'} className={'form-content'} onFinish={onFinish} initialValues={initialValues}>
          <FormItem
            name={'status'}
            type={'select'}
            label={'Assignment status'}
            placeholder={'Select assignment status'}
            selectOptions={statusSelectOptions as SelectOptionsType[]}
          />
          <Row justify={'end'}>
            <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} style={{ marginRight: 12 }} />
            <Button text={'Confirm selection'} btnType={'primary'} htmlType={'submit'} loading={updatingApplication} />
          </Row>
        </Form>
      )}
    </>
  )
}
export default ChangeAgentStatus
