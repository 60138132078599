import { Feature, Point } from 'geojson'
import { FC } from 'react'
import { Marker } from 'react-map-gl'

type Props = {
  feature: Feature<Point>
  index?: number | string
  onClick?: () => void
}
const MarkerRender: FC<Props> = ({ feature, index, onClick }) => {
  return (
    <Marker
      key={index}
      longitude={feature?.geometry?.coordinates?.[0]}
      latitude={feature?.geometry?.coordinates?.[1]}
      onClick={onClick}
    />
  )
}

export default MarkerRender
